import { createGlobalStyle } from 'styled-components';
import './flaticon.css';
import './sourcesans.css';
import '../fonts/font-awesome/css/font-awesome.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  .mclr{
    color:#000 !important;
  }


  .sticky-nav-active #desktopmenu ul a, .sticky-nav-active #desktopmenu ul span {
    color: rgb(37, 37, 37) ;
}


.rc-anchor-normal {
  height: 74px;
  width: 229px !important;
}
.mfw{
  gap:2rem;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
}
.w14{
  width: 14rem;
}

.border-white {
  border: 1px solid #EEEEEE;
  padding: 1rem;
  height: 48px;
  width: 100%;
  left: 51px;
  top: 111px;
  
  borderRadius: 0px;
  margin-bottom: 1rem;
}
.border-white1 {
  border: 1px solid #EEEEEE;
 
}
.span1{
  margin-top: -10px;
  font-size: 14px;
    
    color: #f25663;
}

.span3{
  
  font-size: 14px;
    
    color: #f25663;
}
.border-red {
  border: 2px solid #f25663 !important;
  padding: 1rem;
  height: 48px;
  width: 100%;
  left: 51px;
  top: 111px;
  
  margin-top: 3px;
  borderRadius: 0px;
  margin-bottom: 5px;

 
}
.border-red1 {
  border: 2px solid #f25663 !important;
 
}
.justify{
  
  justify-content: center !important;
}


.jhtovm #desktopmenu ul a, .jhtovm #desktopmenu ul span {
  padding: 10px 20px;
  color: #252525;
}
  .red {
    
    

    color: #F25663;
  font-family: Open Sans;
  font-Size: 18px;
  font-Weight: 700;
  line-Height: 25px;
  letter-Spacing: 0em;
  text-Align: left;

  
}
.bookhead{
  margin-Top: 0rem;
  font-Family: Open Sans;
  font-Size: 42px;
  font-Weight: 700;
  line-Height: 60px;
  letter-Spacing: 0em;

  color:000000,
}
.ikon{
  margin-left: -10rem;
}
.img1{
  width: 60% !important;
}
.img2{
  width: 30px !important;
}
.bm{
  text-align: left;
  width: 100%;
height: 150px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* or 150% */
margin-bottom: 3rem;


color: #202020;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.bm2{
  text-align: left;
  width: 100%;
height: 150px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* or 150% */
margin-bottom: 3rem;


color: #202020;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.btm{
  margin-bottom:1rem;
}
.bt{
  margin-top: -3rem;
  text-align: left;


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #202020;
}
// .carousel .control-dots .dot {
//   transition: opacity .25s ease-in;
//   opacity: .3;
//   filter: alpha(opacity=30);
//   box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
//   background: #000 !important;
//   border-radius: 50%;
//   width: 15px !important;
//   height: 15px !important;
//   /* margin-top: 8rem; */
//   cursor: pointer;
//   display: inline-block;
//   margin: 0px 8px;
// }



.divcard1:hover {
  box-Shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
   background: linear-gradient(129.43deg, #F25663 1.34%, #F25A86 99.91%);;
  width: 263.32px;
  height: 242.35px;
  color:#fff !important;
  left: 673.8px;
  top: 880px;
  -webkit-filter: drop-shadow(0px 3.65403px 10.9621px rgba(0,0,0,0.12));
  filter: drop-shadow(0px 3.65403px 10.9621px rgba(0,0,0,0.12));
  border-radius: 4.66057px;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: black !important;
}
.ctitle{
  margin-top: 2rem;

  text-align: left;
  
width: 443px;
height: 148px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 800;
font-size: 64px;
line-height: 74px;
/* or 116% */


color: #202020;
}
.phead{
  
  font-Family: Open Sans;
  font-Size: 18px;
  font-Weight: 400;
  line-Height: 25px;
  letter-Spacing: 0em;
  text-Align: left;

  
}

.mt1r{
  margin-top: 1rem;
}
.inputstyle{
  // background: #EEEEEE !important;
  // color: #EEEEEE;
  border: 1px solid #EEEEEE;
  padding: 1rem;
  height: 48px;
  width: 100%;
  left: 51px;
  top: 111px;
  
  borderRadius: 0px;
  margin-bottom: 1.4rem;

}

.bookhead3 {
  color:#fff;
  margin-Top: 0rem;
  font-Family: Open Sans;
  font-Size: 35px;
  font-Weight: 700;
  line-Height: 60px;
  -webkit-letter-Spacing: 0em;
  -moz-letter-Spacing: 0em;
  -ms-letter-Spacing: 0em;
  letter-Spacing: 0em;
  color: 000000,;
}

.red7 {
  color: #fff;
  font-family: Open Sans;
  font-Size: 18px;
  font-Weight: 700;
  line-Height: 25px;
  -webkit-letter-Spacing: 0em;
  -moz-letter-Spacing: 0em;
  -ms-letter-Spacing: 0em;
  letter-Spacing: 0em;
  text-Align: left;
}
.divcard{
  padding: 2rem;
  box-Shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
color:#000 !important;
  // height: 470px;
  border-radius: 4.66057px;
}
.img5{
background-image: url("/static/Background-3995d3065bce4a9e14352406facdd6ec.jpg");
background-color: #cccccc;
}




.react-tel-input .form-control {
  border: 1px solid #EEEEEE;
  padding: 1rem;
  height: 48px !important;
  width: 100% !important;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  /* border: 1px solid #CACACA; */
  border-radius: 5px;
  line-height: 25px;
  /* height: 50px; */
  /* width: 300px; */
  outline: none;
}



@media (min-width: 900px) and (max-width: 4180px) {
  .mobshow{
    display:none;
  }}


@media (min-width: 1200px) and (max-width: 2180px) {
  .mobshow{
    display:none;
  }
  .divcard9 {
    width: 20rem !important;
   
}

  .mt3r{
    margin-top: 3rem;
  }


  .smw1{
    width: 8rem;
  }

  .mtr4{
    margin-top: 4rem !important;
}
  }
  .mpl{
    margin-top: 5rem !important;
  
  }

.mt24{
  margin-top: 2rem;
}
  
  .mt5l{
    margin-top: 5rem !important;
  
  }

  .mty3{
    
    margin-top: 3rem;

  }

  .g5{
    gap:5rem !important;
  }
  .g6 {
    gap: 2rem !important;
}

}
.fs12{
  font-size: 11px;
}


}
.dot {
  margin-top: 1rem;
  height: 5rem;
  width: 5rem;
  background-color: #F25665;
  border-radius: 50%;
  display: inline-block;
}


.simg {
    width: 3rem;
    margin-left: 16px;
    margin-top: 19px;
}
@media (min-width: 1300px) and (max-width: 2180px) {

  .mt33{margin-top: 5rem;}


  .flex-container1 > div {
    text-align: left;
    margin: 10px;
    padding: 20px;
  
  }

  .headmt{
    margin-top: 5rem;
  }
  .mhidd{

    display:none !important;
  }
  
  .titleh{
    margin-top: 7rem;
  
  }
  
}
.imgwid {
  margin-top: 5rem;
  width: 30rem;
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .mb2{
    margin-top: 5rem;
    height: 10rem;
  }
}
@media (min-width: 800px) and (max-width: 1300px) {
  .flex-container1 > div {
    text-align: left;
    margin: 10px;
    padding: 20px;
  
  }

.fttext {
  margin-left: 8rem;
  height: 100px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #FFFFFF;
  margin-top: 1rem;
}
.imgwid {
 
  width: 37rem;
}

.g5 {
  gap: 11rem !important;
}
.gg5{

  margin-top: -7rem !important;
}
.g6 {
  gap: 3rem !important;
}
.macs{
  max-width: 1212px !important;
    width: 100% !important;
}
.divcard {
  margin-top: -10rem !important;
  padding: 2rem;
  box-Shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  color: #000 !important;
  // height: 470px;
  border-radius: 4.66057px;
}


.mhidd{

  display:none !important;
}
}
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.phead5{
  color:#ffffffd4;
   font-Family: Open Sans;
  font-Size: 18px;
  font-Weight: 400;
  line-Height: 25px;
  -webkit-letter-Spacing: 0em;
  -moz-letter-Spacing: 0em;
  -ms-letter-Spacing: 0em;
  letter-Spacing: 0em;
  text-Align: left;
}
@media (min-width: 900px) and (max-width: 1200px) {
  
  .mhidd2{

    display:none !important;
  }

}

.g5{
  gap: 8rem;
}
.g6{
  gap: 8rem;
}

@media (min-width: 800px) and (max-width: 1200px) {



  .mt7{
    
    margin-top: 7rem !important;

  }}


  * This is a stylization for a certain example, you can stylize it for your own layout */ 
.change-photos {
  width: 300px;
}
.change-photos .change-photo img {


  height: 100%;
  object-fit: cover;
}




/* Hiding the second picture right away */
.change-photos .change-photo:nth-of-type(2) {
  display: none;
}

/* When you hover your cursor over the parent change-photos class, the first picture is hidden, and the second is shown */

.change-photos:hover .change-photo:nth-of-type(1) {
  display: none;
}

.change-photos:hover .change-photo:nth-of-type(2) {
  display: block;
}

.change-photos:hover {
  cursor: pointer;
}





  .img {

    position: absolute;
    
    }
    .hover_img {

      position: absolute;
      
      top: 0;
      
      left: 0;
      
      display: none;
      
      }
      .img:hover .hover_img {

        display: inline;
        
        }
.cf{
  color: #fff;
}

.fs14{
  font-size:12px;
}

.rem1{
  
    width: 1rem;
}
.flex-containerr {
  display: flex;
  flex-direction: row;


}
.flex-containerrmob {
  display: flex;
  flex-direction: row;


}

@media (min-width: 200px) and (max-width: 700px) {
  .desk-show{
    display:none
  }


  .mt35{
    margin-top: -35px !important;

  }
.mt50{
  margin-top: -55px !important;

}
  .mt6r{
    margin-top: 6rem;
  }
  .mt4r{
    margin-top: 4rem;

  }
  .webred{
    font-size: 15px;

  }
  .web2{
    font-size: 15px;
  }
  .web3{
    font-size: 20px;
  }
  .web4{
    font-size: 16px;
  }
  .web5{
    font-size: 20px;
  }
  .web6{
    font-size: 18px;
  }
  .web7{
    line-height: 22px;
    font-size: 16px;
  }
  .ht45{
    height: 46rem
  }
  .width3
  .ickonwidh{
    width: 2rem;
    margin-top: 21px;
  }
  .mat2{
    margin-top: -2rem
  }
  .width30{
    width:16rem
  }
  .pad5{
    padding: 2rem;
  }
.wid23{
  width: 22.5rem;
}
.width13r{
  
  width: 16rem;
}
  .top10r{
    top: 2rem;
  }
  .width20r{
    width: -1rem;
  }
  .mt10r{
    margin-top: -7rem;
  }
  .flex-containerr {
    display: flex;
    flex-direction: column;
  }
  .flex-containerrmob {
    display: flex;
    flex-direction: column-reverse;
  }
  .widbaner{
    padding: 14px;
    font-size: 23px;
    width: 23rem;
  }
  .pd14{
    padding: 14px;

  }
}

.card1{
  height: 11rem;
  /* max-width: 449px; */
  padding: 30px;
  margin: 40px auto;
  border-radius: 5px;
  box-shadow: 0 0 15px 7px rgb(0 0 0 / 15%), inset 0 0 7px 2px rgb(0 0 0 / 15%);
  font-size: 12px;
}

@media (min-width: 1000px) and (max-width: 1300px) {

  .mtd7{
    margin-top: 5rem;
  }

  .mlf10{
    margin-left: 0rem;
  }

  .ickonwidh{
    width: 70px;
    
  }

  .ht45{
    height: 45rem
  }
  .width30{
    width:13rem
  }
  .wid23{
    width: 28rem;
  }
  .pad5{
    padding: 3rem;
  }
.mt32{
  
  margin-top: -32px;

}
.mt42{
      margin-top: -42px;
}
  .gap0{
    gap: 1rem !important;
  }


  .mt20{
    margin-top: 20px;
  }
  .width13r{
  
    width: 27rem;
  }
  .top10r{
    top: 5rem;
  }

.mb0r{
  margin-bottom: 0rem !important;
}

.f21{
  font-size: 20px !important;
}
  .mt10rem{
    margin-top: -12rem;
  }
  .width20r{
    width: 31rem;
  }
  .left1{
    left: 4rem
  }
  .lh1{
    line-height: 0;
  }
  .mt10r{
    margin-top: 4rem;
  }
  .widbaner{
    width: 48rem
  }
}
@media (min-width: 1300px) and (max-width: 2900px) {

  .mtd7{
    margin-top: 5rem;
  }

  .mlf10{
    margin-left: -10rem;
  }

  .ickonwidh{
    width: 70px;
    
  }

  .ht45{
    height: 45rem
  }
  .width30{
    width:13rem
  }
  .wid23{
    width: 28rem;
  }
  .pad5{
    padding: 8rem;
  }
  .width13r{
  
    width: 27rem;
  }
  .top10r{
    top: 6rem;
  }


  .mt10rem{
    margin-top: -12rem;
  }
  .width20r{
    width: 31rem;
  }
  .left1{
    left: 14rem
  }
  .lh1{
    line-height: 0;
  }
  .mt10r{
    margin-top: 4rem;
  }
  .widbaner{
    width: 48rem
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .mt10rem{
    margin-top: -10rem;
  }
  .flex-containerrmob {
    display: flex;
    flex-direction: column;
  }
  .mat2{
    margin-top: -2rem
  }
  .width30{
    width:30rem
  }
  .flex-containerr {
    display: flex;
    flex-direction: column;
  }
  .wid23{
    width: 30rem;
  }
  .pad5{
    padding: 5rem;
  }
  .width13r{
  
    width: 30rem;
  }
 

  .pd14{
    padding: 14px;

  }
.mt10r{
  margin-top: 7rem;
}
  .widbaner{
    padding: 14px;
    font-size: 30px;
    width: 43rem;

  }
 
  
  .centercircle{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .mty3{
    
    margin-top: 7rem;

  }
  .mt2{
    margin-top: 2rem;
  }
.ml56{
  margin-top: -4rem;

}

  .mt41{
    margin-top: 5rem;
  }
  .book {
    width: 16rem !important;
    margin-top: 1rem !important;
}
  .red7 {
    margin-top: 7rem !important;
   
}
  .mt5l{
    margin-top: 0rem !important;
  
  }
  .g5 {
    gap: 3rem;
}

.g6 {
  gap: 3rem;
}

 
  .wrap2{
  
      
    
      display: flex;
      flex-wrap: wrap;
    }
.flex-container3 {
  margin-bottom: 3rem;
}
  .bm{
    margin-bottom: 8rem !important;
  }
  .widmob1{
    max-width: 47rem;
  }
  .mt4{
    margin-top: -4rem;
  }
  .ftr {
    padding: 1rem;
    height: 264px !important;
    left: -50px;
    top: 3152px;
    background: linear-gradient(89.96deg,#F25664 0.03%,#F25A85 99.96%);
}

  .mhidd1{

    display:none !important;
  }
  .ikon1{
    margin-top: -73rem;
  }

  .phead {
   
    font-Family: Open Sans;
    font-Size: 18px;
    font-Weight: 400;
    line-Height: 25px;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    letter-Spacing: 0em;
    text-Align: left;
}

  .divcard {
    
    padding: 2rem;
    box-Shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    // height: 470px;
    color:#000;
    border-radius: 4.66057px;
}
.bookhead {
 
  font-Family: Open Sans;
  font-Size: 38px;
  font-Weight: 700;
  line-Height: 60px;
  -webkit-letter-Spacing: 0em;
  -moz-letter-Spacing: 0em;
  -ms-letter-Spacing: 0em;
  letter-Spacing: 0em;
  color: 000000,;
 
}
  
  .red {
    color: #F25665;
    font-family: Open Sans;
    font-Size: 18px;
    font-Weight: 700;
    line-Height: 25px;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    letter-Spacing: 0em;
    text-Align: left;
 
}


}

.mb5{
  margin-bottom: 3rem;
}


.book {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  width: 20rem;

  margin-top: -6rem;
}
.smw16 {
  width: 14rem;
}
.smw17 {
  font-weight: 700;
  font-size: 20px;
  width: 14rem;
}
.divcard7 {
  margin-bottom: 2rem;

  padding: 2rem;
  box-Shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  color: #000 !important;

  border-radius: 12px;
}
.btnstyle1 {
  margin-Top: 1rem;
  border: 1px solid #F25665;
  height: 49px;
  width: 100%;
  left: 51px;
  top: 469px;
  border-Radius: 6px;
  padding: 14px 25px 14px 25px;
  color: #fff;
  background: #F25665;
}
.bgw{
  background: #fff;
}

@media (min-width: 200px) and (max-width: 400px) {
  .centercircle{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
.mcard{
  margin-left: -2rem;
  margin-right: 5rem;
  width: 23rem;
}
}
@media (min-width: 300px) and (max-width: 600px) {
  .centercircle{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .g5 {
    gap: 0rem !important;
}


  .bookup{
    margin-top: -5rem;
    margin-bottom: 2rem;
  }

  .bt {
    margin-top: -4rem;}
  .sf{
    margin-bottom: 6rem;

  }

  .mlb{
    margin-top: -3rem;
    margin-bottom: 2rem;
  }
  

.ml3{
  
  margin-left: 4rem;

}
.ml55{
  
  margin: auto;

}

.bookhead3 {
    color: #fff;
    margin-Top: 0rem;
    font-Family: Open Sans;
    font-Size: 25px !important;
    font-Weight: 700;
    line-Height: 40px;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    letter-Spacing: 0em;
    color: 000000,;
}
  .book {
    width: 100% !important;
    margin-top: -2rem !important;
}

  .red7 {
    margin-top: 10rem !important;
    
}
  .mtl4{
    margin-top: -4rem !important;
  }
  .g5 {
    gap: 2rem;
}
.g6 {
  gap: 1rem !important;
}
}
@media (min-width: 300px) and (max-width: 700px) {

  .centercircle{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .mty7{
    
    margin-top: 7rem !important;
  
  }
  .mty3 {
    margin-top: -2rem ;
}
  .mtr4{
    // margin-top: -4rem !important;
}
.mt5l {
  margin-top: 3rem !important;
}

  .wrap3{

  
    display: flex;
    flex-wrap: wrap;
  }

  .phead{
    max-width: 20rem!important;
  }
  .dnone{
    display:none;
  }

  .ml2r{
    margin-left: 2rem !important;
  }
  .mauto{
    margin: auto ;
  }
  
  .p1 {
    padding: 1rem;
    margin-bottom: 7rem;
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    -webkit-letter-spacing: 0em;
    -moz-letter-spacing: 0em;
    -ms-letter-spacing: 0em;
    letter-spacing: 0em;
    /* text-align: center; */
    color: #000;
    max-width: 25rem;
}
  .fttext {
    margin-bottom: 6rem !important;
    margin-left: 0rem !important;
    height: 100px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #FFFFFF;
}


  .ftr {
    width: 26rem !important;
    padding: 1rem;
    height: 330px !important;
  
    background: linear-gradient(89.96deg,#F25664 0.03%,#F25A85 99.96%);
}
  .btm {
    margin-bottom: 1rem;
    margin-top: -6rem !important;
}
  .divcard3{
    margin-top: -5rem !important;
    
  }
  .divcard5{
    
    margin-left: 4rem !important;
  }
  .mcard{
 
    margin-left: -7px;

    margin-right: 5rem;
    width: 23rem;
  }
  .mobdisplay{
    display:none;
  }
  }
  @media (min-width: 750px) and (max-width: 900px) {
    .desk-show{
      display:none;
    }
    .wrap1{

  
    display: flex;
    flex-wrap: wrap;
  }

  .m7{
    margin-top:7rem;
  }
  .ctitle {
    
    margin-top: 5rem;
    text-align: left;
    width: 382px !important;
    height: 148px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 74px;
    color: #202020;
}


  .ml0{
    margin-left: 0rem;
}
  }
 
}
.btm3 {
  margin-bottom: 1rem;
}
.flexcons{
  display: flex;
    flex-wrap: wrap;
}
  @media (min-width: 200px) and (max-width: 700px) {

    .imgwid {
      
      width: 20rem !important;
  }
    .headtitle{
      width: 23rem;
      
    }
    .bm {
      margin-bottom: 14rem !important;
      width: 26%  !important;}
      .bm2 {
        margin-bottom: 18rem !important;
        width: 26%  !important;}
    .ctitle {
      // margin-top: 5rem;
      text-align: left;
      width: 443px;
      height: 148px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 48px !important;
      line-height: 64px !important;
      color: #202020;
  }
    .testm{
      margin-bottom: 8rem;
    margin-left: -2rem;
    }
    .img1 {
      width: 30% !important;
      margin-left: -66rem !important;
  }
    .li1 {
      width: 22rem !important;
    
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
  
      color: #000000;
  }
    .center2 {
      display: flex;
      align-items: center;
      justify-content: center;
  }
    .widmob{
      margin-left: 3rem;
      
      margin-bottom: 1rem;
      width: 0rem;
    }
    .widmob1{
      width: 25rem;
    }
    .rcen{
      display: flex !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
    .flex-container {
      display: flex important;
      flex-direction: column !important;
    
      background: #191C3C;
      
    }
 
    .flex-container1 {
      display: flex !important;
      flex-direction: column !important;
    
      background: #0000;
      
    }
    .flex-container5 {
      display: flex !important;
      flex-direction: column !important;
    
      background: #0000;
      
    }

  }

  .bookd{
    color:#fff !important
  }
  .bookd:hover{
    color:blue  !important
  }
@media (min-width: 200px) and (max-width: 700px) {

.mtt4{
      margin-top: -4rem;
}

.divcard {
    
    box-Shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    color: #000 !important;
    // height: 490px;
    border-radius: 4.66057px;
}

 
  .mhidn{
    margin-left: -14rem;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .mhid{

    display:none !important;
  }

  .md{
    margin-left: 1rem !important;
    margin-right: 3rem !important;
  }
  .red {
    color: #F25663;
    font-family: Open Sans;
    font-Size: 18px;
    font-Weight: 700;
    line-Height: 25px;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    letter-Spacing: 0em;
    text-Align: left;
   
}
  .inputstyle{
    // background: #EEEEEE;
    // color: #EEEEEE;
    border: 1px solid #EEEEEE;
    padding: 1rem;
    height: 48px;
    width: 100% !important;
    
    
    borderRadius: 0px;
    margin-bottom: 1rem;
  }
  .bookhead {
    margin-Top: 0rem;
    font-Family: Open Sans;
    font-Size: 34px !important;
    font-Weight: 700;
    line-Height: 50px !important;
    -webkit-letter-Spacing: 0em;
    -moz-letter-Spacing: 0em;
    -ms-letter-Spacing: 0em;
    letter-Spacing: 0em;
    color: 000000,;
}
  
}

.smw{
  width: 4rem;
}


.cardp{
  margin-top: 7px;

  font-Family: Open Sans;
  font-Size: 24px;
  font-Weight: 600;
  line-Height: 22px;
  letter-Spacing: 0em;
  text-Align: left;
}

.btnstyle{
  margin-Top: 1rem;
  border: 1px solid #F25665;
  height: 49px;
  width: 142px;
  left: 51px;
  top: 469px;
  border-Radius: 6px;
  padding: 14px 25px 14px 25px;
  color: #fff;
  background: #F25665;
}

.top2{
  margin-top: -2rem;
}
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }
  .carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
    background: #fff;
    border-radius: 50%;
    width: 22px !important;
    height: 22px !important;;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
  .ft{
    
    
    margin-bottom: -1rem;

    height: 38px;
left: 43.57%;
right: 48.43%;
top: calc(50% - 38px/2 - 1073.66px);

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 37px;
/* identical to box height, or 145% */

letter-spacing: 0.233029px;


  }
  
  .fs{
    height: 33px;
left: 43.57%;
right: 46.74%;
top: calc(50% - 33px/2 - 1026.06px);

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18.6423px;
line-height: 33px;
/* identical to box height, or 175% */



  }
  .mt5{
    margin-top:-3rem;
  }
  .btm2{
    margin-bottom: 5rem;
}
  }
 
  .center2{
    width:90%;
  }
  .divcard1{
    width: 263.32px;
height: 242.35px;
left: 673.8px;
color:#000 !important;
top: 880px;
border-radius: 4.66057px;
-webkit-filter: drop-shadow(0px 3.65403px 10.9621px rgba(0,0,0,0.12));
filter: drop-shadow(0px 3.65403px 10.9621px rgba(0,0,0,0.12));
border-radius: 4.66057px;

  }
.rcen{
  display: flex !important;
    align-items: center ;
    justify-content: center !important;
}
  .flex-container2 > div {
    /* margin: 0 30px; */
    text-align: left;
    margin: 0 35px;
    /* margin: 10px; */
    padding: 20px;
}
  .centerdiv{
    
    display: flex;
    align-items: center;
    justify-content: center;
  
  }


  
  .img3{
    background-image: url(
      "../../assets/image/hosting/ikons/Marketing.svg");
              }
    margin-top: 2rem;
    width: 36.7px;
  }
  .divcard1{
    
    padding: 2rem;
    box-Shadow: rgb(100 100 111 / 10%) 0px 7px 29px 0px;
    color: #000 !important;
    height: 242.35px;
    width: 263.32px;
    border-radius: 4.66057px;
}



.ftr{
  

  padding: 1rem;

  height: 169px;
  left: -50px;
  top: 3152px;
  
  background: linear-gradient(89.96deg, #F25664 0.03%, #F25A85 99.96%);


}
.fttext{
  margin-left: 8rem;
height: 100px;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 32px;




color: #FFFFFF;
}
.pbtn{

justify-content: center;
align-items: center;
padding: 18px 72px;
gap: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
/* identical to box height, or 140% */


color: #F25667;

width: 271px;
left: calc(50% - 271px/2 + 475px);
top: 76.52%;
bottom: 22%;

background: #FFFFFF;
border-radius: 8px;
}


.mb1{margin-bottom: 5rem;}

  .red3 {
 
   
    color: #F25663;
    font-family: Open Sans;
    font-Size: 18px;
    font-Weight: 700;
    line-Height: 25px;
    letter-Spacing: 0em;
}
.car1{
  font-family: "Open Sans";
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  color:#F25663;
}
.car2{
  font-family: "Open Sans";
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  color:#000;
}
.car3{
  height: 51px;


  margin-bottom: 2rem;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #000000;
}
.car4{
  height: 51px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #F25666;
}
.flex-container {
  display: flex ;
  flex-direction: row;

  background: #191C3C;
  
}

.flex-container1 {
  display: flex ;
  flex-direction: row;

  background: #0000;
  
}
.flex-container3 {
  display: flex ;
  flex-direction: row;

  background: #0000;
  
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 20px !important;

  top: -36px;
  
}
.p1{
  margin-bottom: 3rem;
  font-family: Inter;
font-size: 50px;
font-weight: 700;
line-height: 65px;
letter-spacing: 0em;
text-align: center;
color:#000;

}
.p2{
  
    font-family: Inter;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: center;
  color:#F25872;
  
  
}
.li1{
  left: 48.69%;
right: 14.06%;
top: 41.24%;
bottom: 47.8%;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 47px;
/* or 263% */


color: #000000;
}
.sf{
  margin-top: -1rem;
  font-family: Open Sans;
font-size: 56px;
font-weight: 700;
line-height: 76px;
letter-spacing: 0em;
text-align: left;

font-family: Inter;
font-size: 56px;
font-weight: 700;
line-height: 76px;
letter-spacing: 0em;
text-align: left;
color:#000;
}
.sf2{
  font-family: Open Sans;
font-size: 56px;
font-weight: 700;
line-height: 76px;
letter-spacing: 0em;
text-align: left;

font-family: Inter;
font-size: 56px;
font-weight: 700;
line-height: 76px;
letter-spacing: 0em;
text-align: left;
color:#F25666;

}
.f1{
  


font-family: 'Open Sans';

font-weight: 600;
font-size: 19px;
line-height: 30px;
text-align: center;
color:#FFFFFF;

}
.center1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.flex-container > div {
 
  margin: 10px;
  padding: 20px;
  

}

.flex-container3 > div {
  text-align: left;
  margin: 10px;
  padding: 20px;

}

.car5{
  display:flex;
}
  .red1{
    width: 123px;
height: 26px;
margin-left
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
/* identical to box height, or 140% */


background: linear-gradient(89.75deg, #F25663 0.12%, #F25986 99.72%);
-webkit-background-clip: text;

  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

 
    .wrapper {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
    }
    .wrapper > div {
      flex: 1 1 150px;
    }
    
    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }
`;
